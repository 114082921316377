import React from 'react';

import './Modal.scss';

import sprite from '../../assets/images/sprite.svg';
import Image from "../../assets/images/murr-modal.svg";
import { useActions } from '../../hooks/useActions';

const Modal = ({ setIsOpen }) => {

    const { removeError } = useActions()

    const handleCloseModal = () => {
        setIsOpen(false)
        removeError()
    }

    return (
        <div className='modal modal--active'>
            <div className="modal__scroll">
                <div className="modal__wrap">
                    <div className="modal__main">
                        <button className="modal__close" onClick={handleCloseModal}>
                            <svg className="modal__close-icon">
                                <use href={sprite + '#icon-close'}></use>
                            </svg>
                        </button>
                        <div className="modal__body">
                            <div className="modal__block">
                                <h3 className="modal__title">На вашем балансе недостаточно средств </h3>
                                <p className="modal__description">Пополните баланс</p>
                            </div>
                            <a href='https://aisolla.ru/#rec564559007' target='_blank' rel='noreferrer' className={'btn modal__btn'} onClick={handleCloseModal}>
                                Пополнить баланс
                            </a>
                            {/* <Button className={'modal__btn'} title={'Пополнить баланс'} /> */}
                        </div>
                        <picture className="modal__img">
                            <img src={Image} alt="" />
                        </picture>
                    </div>
                </div>
            </div>
            <div className="modal__overlay" onClick={handleCloseModal}></div>
        </div>
    );
}

export default Modal;