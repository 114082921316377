import React from 'react'
import { ReactComponent as LoaderIcon } from './../../assets/icons/loader.svg'

const AppLoadingScreen = () => {

  return (
    <div className='app-loading-screen'>
      <LoaderIcon />
    </div>
  )
}

export default AppLoadingScreen