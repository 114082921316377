import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../services/api';
import TokenService from '../../services/tokenService';

export const getRequestsHistory = createAsyncThunk(
    'descriptions/requestsHistory',
    async (_, { rejectWithValue }) => {
        return await instance.get('users/return_users_queries', {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${TokenService.getLocalAccessToken()}`,
            }
        })
            .then(res => res.data)
            .catch(e => rejectWithValue(e.message))
    }
);

export const getNumberOfTry = createAsyncThunk(
    'descriptions/numberOfTry',
    async (values, { rejectWithValue }) => {
        return await instance.post('users/return_product_name_retries',
        values,
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${TokenService.getLocalAccessToken()}`,
            }
        })
            .then(res => res.data)
            .catch(e => rejectWithValue(e.response.status))
    }
);

export const generateDescription = createAsyncThunk(
    'descriptions/generate',
    async (values, { rejectWithValue }) => {
        return await instance.post('users/generate_product_description',
        values,
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${TokenService.getLocalAccessToken()}`,
            }
        })
            .then(res => {
                var _tmr = window._tmr || (window._tmr = []);
                _tmr.push({ id: '3317476', type: 'reachGoal', goal: 'success_generate_description' });
                return res.data
            })
            .catch(e => rejectWithValue(e.response.status))
    }
);

export const getKeywords = createAsyncThunk(
    'descriptions/getKeywords',
    async (values, { rejectWithValue }) => {
        return await instance.post('users/generate_keywords',
        values,
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${TokenService.getLocalAccessToken()}`,
            }
        })
            .then(res => res.data)
            .catch(e => rejectWithValue(e.message))
    }
);