import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import ClueKeywords from '../../Keywords/ClueKeywords';
import CharacteristicsList from '../../CharacteristicsList/CharacteristicsList';
import InputKeywords from '../../UI/Input/InputKeywords';
import { useSelector } from '../../../hooks/useSelector';
import { useActions } from '../../../hooks/useActions';

const validation = {
  name: Yup.string().max(970).required('Обязательное поле'),
  characteristics: Yup.array().test('validate length', 'Превышено максимальное количество символов', (value) => {
    return value.map(item => item.value).join(', ').length <= 970
  }),
  info: Yup.array().test('validate length', 'Превышено максимальное количество символов', (value) => {
    return value.map(item => item.value).join(', ').length <= 970
  }),
  keywords: Yup.array().test('validate length', 'Превышено максимальное количество символов', (value) => {
    return value.map(item => item.value).join(', ').length <= 956
  }),
};

const yupValidationScheme = Yup.object({ ...validation });


const GenerateDescrForm = ({ tooltipStep, setTooltipStep, initialState, focusKeywords, setFocusKeywords, formRef }) => {
  const { generateDescription } = useActions()
  const { numberOfTry, error, errorStatus, loadingGenerateDescr } = useSelector(state => state.descriptions)

  const handleSubmit = async (values, { setSubmitting, errors, setStatus, resetForm }) => {
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({ id: '3317476', type: 'reachGoal', goal: 'generate_description' });
    const dto = {
      name: values.name.trim().toLowerCase(),
      characteristics: values.characteristics.map(item => item.value).join(', ').toLowerCase(),
      info: values.info.map(item => item.value).join(', ').toLowerCase(),
      keywords: values.keywords.map(item => item.text).join(', ')
    }
    generateDescription(dto)
    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialState}
      validationSchema={yupValidationScheme}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ values, setValues, isSubmitting, status, errors, touched, handleChange }) => {
        return (
          <Form className="generation__body-left">
            <div className="generation__block">
              <div className="generation__input-block">
                <Input
                  name={'name'}
                  className={'generation__input'}
                  id={'generation-goods-name'}
                  label={'Название товара'}
                  placeholder={'Ввести название'}
                  type={'text'}
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  isDisplayError={true}
                />
              </div>
              <div className="generation__input-block">
                <Input
                  name='rawCharacteristics'
                  className={'generation__input'}
                  id={'generation-character'}
                  label={'Характеристики товара'}
                  placeholder={'Ввести характеристики'}
                  type={'text'}
                  step={1}
                  tooltipStep={tooltipStep}
                  changeTooltipStep={setTooltipStep}
                  tooltip={'bottom'}
                  touched={touched}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  isDisplayError={true}
                  note={'Введите смысловые фразы через Enter'}
                />
                <input name='characteristics' className='hidden__input characteristics__input' onChange={handleChange} />
                {
                  !!values.characteristics.length &&
                  <CharacteristicsList values={values} setValues={setValues} name='characteristics' />
                }
              </div>
              <div className="generation__input-block">
                <Input
                  name='rawInfo'
                  className={'generation__input'}
                  id={'generation-goods-name'}
                  label={'Дополнительная информация'}
                  placeholder={'Ввести другую информацию'}
                  type={'text'}
                  step={2}
                  tooltipStep={tooltipStep}
                  changeTooltipStep={setTooltipStep}
                  tooltip={'top'}
                  touched={touched}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  isDisplayError={true}
                  note={'Введите смысловые фразы через Enter'}
                />
                <input name='info' className='hidden__input info__input' onChange={handleChange} />
                {
                  !!values.info.length &&
                  <CharacteristicsList values={values} setValues={setValues} name='info'/>
                }
              </div>
              <div className="generation__input-block">
                <InputKeywords
                  name='keywords'
                  className={'generation__input'}
                  id={'generation-goods-name'}
                  label={'Ключевые слова'}
                  placeholder={'Вставить ключевые слова '}
                  type={'text'}
                  step={3}
                  tooltipStep={tooltipStep}
                  changeTooltipStep={setTooltipStep}
                  tooltip={'bottom'}
                  touched={touched}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  isDisplayError={true}
                  setFocus={setFocusKeywords}
                />
                {
                  focusKeywords && !!values.name.length &&
                  <ClueKeywords className='keywords--indent' values={values} setValues={setValues} setFocusKeywords={setFocusKeywords}/>
                }
              </div>
            </div>
            {
              error && (errorStatus === 429 || errorStatus === 404) &&
              <div className='error request-error'>Ошибка запроса. Попробуйте еще раз</div>
            }
            <span className="generation__attempts">При изменении названия описание будет считаться как новое</span>
            <Button
              className={'generation__btn'}
              title={'Создать описание'}
              step={4}
              tooltipStep={tooltipStep}
              changeTooltipStep={setTooltipStep}
              tooltip={'top'}
              type='submit'
              isLoading={loadingGenerateDescr}
            />
            {
              numberOfTry !== null && !!values.name.length
              ?
              <span className="generation__attempts">
                {
                  numberOfTry !== 0
                  ?
                  `Осталось попыток: ${numberOfTry}`
                  :
                  'Следующая генерация на товар с данным названием будет считаться как генерация новой карточки'
                }
              </span>
              :
              null
            }
          </Form>
        )
      }}
    </Formik>
  )
}

export default GenerateDescrForm