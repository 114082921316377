import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useActions } from '../../../hooks/useActions';


const initialState = {
  email: '',
};

const validation = {
  email: Yup.string().email('Некорректный email').required('Обязательное поле'),
};

const yupValidationScheme = Yup.object({ ...validation });


const RestorePassForm = ({ setEmail, isRestoreSend }) => {
  const { restorePass } = useActions()
  const [incorrectData, setIncorrectData] = useState()

  const handleSubmit = async (values, { setSubmitting, errors, setStatus, resetForm }) => {
    restorePass({ values, setEmail, isRestoreSend, setIncorrectData})
    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialState}
      validationSchema={yupValidationScheme}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, isSubmitting, status, errors, touched, handleChange }) => {
        return (
          <Form>
            <Input
              name={'email'}
              className={'auth__input'}
              id={'restore-email'}
              label={'Введите email, который вы использовали для входа на сайт'}
              placeholder={'Введите почту'}
              error={'Неверная почта!'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              incorrectData={incorrectData}
              setIncorrectData={setIncorrectData}
            />
            {
              incorrectData &&
              <p className='error request-error'>Пользователь с данным почтовым адресом не существует!</p>
            }
            {
              errors && touched.email &&
              <p className='error request-error'>
                {errors.email}
              </p>
            }
            <Button className={'auth__btn'} title={'Восстановить'} type='submit'/>
          </Form>
        );
      }}
    </Formik>
  )
}

export default RestorePassForm