import React from 'react';

import Keywords from '../../Keywords/SelectedKeywords';
import Tooltip from '../../Tooltip/Tooltip';

import './Input.scss';

import sprite from '../../../assets/images/sprite.svg';

const InputKeywords = (props) => {
    const handleOpenTooltips = () => {
        if (props.tooltipStep !== 0) {
            props.changeTooltipStep(0)
        } else props.changeTooltipStep(props.step)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    return (
        <div className={"input-field " + props.className}>
            { props.label ? 
                <div className="input-field__inner">
                    <label htmlFor={props.id} className="input-field__label">
                        <span>{props.label}</span>
                        { props.tooltip ? 
                            <button className="input-field__tooltip" type='button' onClick={handleOpenTooltips}>
                                <svg className="input-field__tooltip-icon">
                                    <use href={sprite + '#icon-info'}></use>
                                </svg>
                            </button>
                        :
                            null
                        }
                    </label>
                </div>
              : 
                null
            }
            <div className="input-field__group">
                {   !!props.values.keywords.length &&
                    <Keywords values={props.values} setValues={props.setValues}/>
                }
                <input name='rawKeywords' value={props.values.rawKeywords} type={props.type} id={props.id} className="input-field__input input__keywords" placeholder={props.placeholder} onChange={props.handleChange} onKeyDown={handleKeyDown} onFocus={() => props.setFocus(true)} />
                <input name={props.name} type={props.type} id={props.id} className="hidden__input" onChange={props.handleChange} />
                <div className="input-field__group-border"></div>
            </div>
            { props.error ? 
                <span className="input-field__error">{props.error}</span>
              : 
                null
            }
            { props.tooltip ? 
                <Tooltip className={props.tooltip === 'top' ? 'tooltip--top': 'tooltip--bottom'} step={props.step} tooltipStep={props.tooltipStep} changeTooltipStep={props.changeTooltipStep} />
            :
                null
            }
        </div>
    );
}

export default InputKeywords;