import { createSlice } from '@reduxjs/toolkit';

import { getRequestsHistory, generateDescription, getKeywords, getNumberOfTry } from './DescriptionsThunks';

export const initialState = {
    data: [], // request history
    keywords: [],
    description: '',
    generatedAt: '',
    numberOfTry: null,
    name: '',
    loading: false,
    loadingGenerateDescr: false,
    error: false,
    errorStatus: null,
    numberOfGenerations: null,
    isGetDescr: false,
};

const DescriptionsSlice = createSlice({
    name: 'descriptions',
    initialState,
    reducers: {
        setName: (state, { payload }) => {
            state.name = payload
        },
        removeError: (state) => {
            state.error = false;
            state.errorStatus = null;
        },
        clearData: (state) => {
            state.description = '';
            state.keywords = [];
            state.generatedAt = '';
            state.numberOfTry = null;
        },
        hideDescr: (state) => {
            state.isGetDescr = false
        }
    },
    extraReducers: builder => {
        builder.addCase(getRequestsHistory.pending, state => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(getRequestsHistory.fulfilled, (state, { payload }) => {
            state.data = [...payload.product_cards]
            state.numberOfGenerations = payload.num_of_remaining_cards
            state.loading = false;
        });
        builder.addCase(getRequestsHistory.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
        builder.addCase(generateDescription.pending, state => {
            state.error = false;
            state.loadingGenerateDescr = true;
        });
        builder.addCase(generateDescription.fulfilled, (state, { payload }) => {
            state.description = payload.product_card.description;
            state.generatedAt = payload.product_card.created_at + 'Z'; // UTC
            state.numberOfTry = payload.num_of_retries
            state.loadingGenerateDescr = false;
            state.isGetDescr = true;
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });
        builder.addCase(generateDescription.rejected, (state, { payload }) => {
            state.error = true;
            state.errorStatus = payload
            state.loadingGenerateDescr = false;
        });
        builder.addCase(getKeywords.pending, state => {
            state.error = false;
        });
        builder.addCase(getKeywords.fulfilled, (state, { payload }) => {
            state.keywords = []
            state.keywords = [...payload]
        });
        builder.addCase(getKeywords.rejected, (state, { payload }) => {
            state.error = true;
        });
        builder.addCase(getNumberOfTry.pending, state => {
            state.error = false;
        });
        builder.addCase(getNumberOfTry.fulfilled, (state, { payload }) => {
            state.numberOfTry = payload.num_of_retries
        });
        builder.addCase(getNumberOfTry.rejected, (state, { payload }) => {
            state.error = true;
            state.errorStatus = payload
        });
    },
});

export const DescriptionsReducer = DescriptionsSlice.reducer;
export const DescriptionsActions = {
    ...DescriptionsSlice.actions,
    getRequestsHistory,
    generateDescription,
    getKeywords,
    getNumberOfTry
};
