import React from 'react';

import './CharacteristicsList.scss';

import sprite from '../../assets/images/sprite.svg';

const CharacteristicsList = ({ values, setValues, name }) => {
    const handleRemove = item => {
        setValues(prev => ({
            ...prev,
            [name]: prev[name].filter(char => char.id !== item.id)
        }))
    }

    return (
        <div className="characteristics-list">
            {values[name].map(item => (
                <div className="characteristics-list__item" key={item.id}>
                    <span className="characteristics-list__title">{item.value}</span>
                    <button className="characteristics-list__close" type='button' onClick={() => handleRemove(item)}>
                        <svg className="characteristics-list__close-icon">
                            <use href={sprite + '#icon-close'}></use>
                        </svg>
                    </button>
                </div>
            ))}
            
            {/* <div className="characteristics-list__item">
                <span className="characteristics-list__title">Хлопковые</span>
                <button className="characteristics-list__close">
                    <svg className="characteristics-list__close-icon">
                        <use href={sprite + '#icon-close'}></use>
                    </svg>
                </button>
            </div> */}
        </div>
    );
}

export default CharacteristicsList;