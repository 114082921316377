import React, { useState } from 'react';

import './Header.scss';

import Logotype from "../../assets/images/logotype.svg";
import { useActions } from '../../hooks/useActions';

const Header = () => {
    const [nav, setNav] = useState(false);
    const { logout } = useActions()

    const handleLogout = () => {
        logout()
    }

    return (
        <div className='header'>
            <div className="header__wrapper">
                <picture className="header__logotype">
                    <img src={Logotype} alt="SOLLA" />
                </picture>
                <nav className="nav header__nav">
                    <ul className="nav__list">
                        <li className="nav__item">
                            <a href="https://aisolla.ru/#rec564559007" target='_blank' rel='noreferrer' className="nav__link">Тарифы</a>
                        </li>
                        <li className="nav__item">
                            <a href="https://t.me/solla_support_bot" target='_blank' rel='noreferrer' className="nav__link">Поддержка</a>
                        </li>
                        <li className="nav__item">
                            <button className="nav__link" onClick={handleLogout}>Выйти</button>
                        </li>
                    </ul>
                </nav>
                <button onClick={() => setNav(!nav)} className={!nav ? 'hamburger' : 'hamburger is--active'} aria-label="Открыть меню">
                    <span className="hamburger__inner"></span>
                    <span className="hamburger__inner"></span>
                    <span className="hamburger__inner"></span>
                </button>
                <div className={!nav ? 'header__dropdown' : 'header__dropdown is--active'}>
                    <ul className="nav__list">
                        <li className="nav__item">
                            <a href="https://aisolla.ru/#rec564559007" target='_blank' rel='noreferrer'  className="nav__link">Тарифы</a>
                        </li>
                        <li className="nav__item">
                            <a href="https://t.me/solla_support_bot" target='_blank' rel='noreferrer' className="nav__link">Поддержка</a>
                        </li>
                        <li className="nav__item">
                            <button onClick={handleLogout} className="nav__link">Выйти</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Header;