import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';


const initialState = {
  username: '',
  password: '',
};

const validation = {
  username: Yup.string().email('Некорректный email').required('Обязательное поле'),
  password: Yup.string().required('Обязательное поле'),
};

const yupValidationScheme = Yup.object({ ...validation });


const SignInForm = () => {
  const navigate = useNavigate()
  const { signIn } = useActions()

  const [incorrectData, setIncorrectData] = useState(false)

  const handleSubmit = async (values, { setSubmitting }) => {
    signIn({values, setIncorrectData, navigate})
    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialState}
      validationSchema={yupValidationScheme}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, isSubmitting, status, errors, touched, handleChange }) => {
        return (
          <Form>
            <Input
              name={'username'}
              className={'auth__input'}
              id={'signin-email'}
              label={'Email'}
              placeholder={'Введите почту'}
              error={'Неверная почта!'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              incorrectData={incorrectData}
              setIncorrectData={setIncorrectData}
            />
            <Input
              name={'password'}
              className={'auth__input'}
              id={'signin-password'}
              label={'Пароль'}
              placeholder={'Введите пароль'}
              type={'password'}
              error={'Неверный пароль!'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              incorrectData={incorrectData}
              setIncorrectData={setIncorrectData}
            />
            {
              incorrectData &&
              <p className='error request-error'>Неверный логин или пароль</p>
            }
            {
              errors && touched.username && touched.password &&
              <p className='error request-error'>
                {
                  errors.username ||
                  errors.password
                }
              </p>
            }
            <Button className={'auth__btn'} title={'Вход'} type='submit'/>
          </Form>
        );
      }}
    </Formik>
  )
}

export default SignInForm