import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/Modal/Modal';
import { useSelector } from '../../hooks/useSelector';
import GenerateDescrForm from '../../components/Forms/generateDescription/GenerateDescrForm';
import format from 'date-fns/format';

import './GenerationDescriptions.scss';

import sprite from '../../assets/images/sprite.svg';
import Logotype from "../../assets/images/logotype.svg";
import Image from "../../assets/images/murr-generation.svg";
import { useActions } from '../../hooks/useActions';
import { useNavigate } from 'react-router-dom';

const startInitialState = {
    name: '',
    rawCharacteristics: '',
    characteristics: [],
    rawInfo: '',
    info: [],
    rawKeywords: '',
    keywords: []
}

const GenerationDescriptions = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [tooltipStep, setTooltipStep] = useState(0);
    const [focusKeywords, setFocusKeywords] = useState(false)
    const { name, description, generatedAt, errorStatus, isGetDescr } = useSelector(state => state.descriptions)
    const { clearData, hideDescr } = useActions()
    const navigate = useNavigate()

    const formRef = useRef()

    useEffect(() => {
        if( errorStatus === 403) {
            setIsOpen(true)
        }
    }, [errorStatus])

    const initialStateGeneration = useMemo(() => ({
        ...startInitialState,
        name: name,
    }), [name])

    const handleClearParams = () => {
        clearData();
        formRef?.current?.setValues(prev => {
            prev.keywords.length = 0;
            prev.characteristics.length = 0;
            prev.info.length = 0;
            prev.name = '';
            prev.rawCharacteristics = '';
            prev.rawInfo = '';
            prev.rawKeywords = '';
            return ({
                ...prev
            })
        })
        hideDescr()
    }

    const handleNavToHomeClick = () => {
        handleClearParams();
        navigate('/home')
    }

    return (
        <div className="generation">
            <div className="generation__wrap">
                <div className={`generation__left ${!isGetDescr ? 'is--active' : ''}`}>
                    <div className="generation__container-left">
                        <div className="generation__wrapper-left">
                            <div className="generation__top-left">
                                <picture className="generation__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                <div className="generation__close generation__close--mobile" onClick={handleNavToHomeClick}>
                                    <svg className="generation__close-icon">
                                        <use href={sprite + '#icon-close'}></use>
                                    </svg>
                                </div>
                            </div>
                            <GenerateDescrForm tooltipStep={tooltipStep} setTooltipStep={setTooltipStep} name={name} initialState={initialStateGeneration} focusKeywords={focusKeywords} setFocusKeywords={setFocusKeywords} formRef={formRef} />
                        </div>
                    </div>
                </div>
                <div className={`generation__right ${isGetDescr ? 'is--active' : ''}`}>
                    <div className="generation__container-right">
                        <div className="generation__wrapper-right">
                            <div className="generation__close generation__close--desktop" onClick={handleNavToHomeClick}>
                                <svg className="generation__close-icon">
                                    <use href={sprite + '#icon-close'}></use>
                                </svg>
                            </div>
                            <div className="generation__top-right">
                                <button className="generation__back" onClick={() => hideDescr()}>
                                    <svg className="generation__back-icon">
                                        <use href={sprite + '#icon-back'}></use>
                                    </svg>
                                    <span>Назад</span>
                                </button>
                                {/* <CustomLink className="generation__back" to={'/generation'}>
                                    <svg className="generation__back-icon">
                                        <use href={sprite + '#icon-back'}></use>
                                    </svg>
                                    <span>Назад</span>
                                </CustomLink> */}
                                {
                                    !!description.length &&
                                    <>
                                        <div className="generation__inner">
                                            <span className="generation__value">
                                                Слов <b>
                                                    {description.match(/\p{L}+/gu).length}
                                                </b>
                                            </span>
                                            <span className="generation__value">
                                                Символов <b>
                                                    {description.length}
                                                </b>
                                            </span>
                                            <span className="generation__date generation__date--mobile">
                                                {format(new Date(generatedAt), 'dd.MM.yyyy в HH:mm')}
                                            </span>
                                        </div>
                                        <div className="generation__inner generation__inner--desktop">
                                            <span className="generation__date">
                                                {format(new Date(generatedAt), 'dd.MM.yyyy в HH:mm')}
                                            </span>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="generation__body-right">
                                <div className="generation__main">
                                    {/* add class 'is--hidden' for hidden */}
                                    {
                                        description
                                        ?
                                        <div className="generation__result ">
                                            <p className="generation__description">{description}</p>
                                        </div>
                                        :
                                        <div className='generation__preview'>
                                            <picture className="generation__img">
                                                <img src={Image} alt="" />
                                            </picture>
                                            <p className="generation__help">Здесь будет описание товара...</p>
                                        </div>
                                    }
                                </div>
                                {
                                    description &&
                                    <Button className={'generation__save'} title={'Готово'} onClick={handleClearParams} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <Modal setIsOpen={setIsOpen} />}
        </div>
    );
}

export default GenerationDescriptions;