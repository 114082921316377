import React, { useEffect } from 'react';

import Auth from '../../components/Auth/Auth';
import { useActions } from '../../hooks/useActions';

const SignIn = () => {
    const { setIsOpenModalAfterConfirmEmail } = useActions()

    useEffect(() => {
        const isAfterConfirmEmail = new URLSearchParams(window.location.search).has('email-confirmed')
        if (isAfterConfirmEmail) setIsOpenModalAfterConfirmEmail()
        //eslint-disable-next-line
    }, [])

    return (
        <div className="signin">
            <Auth
                signin
                heading={'Вход'}
                description={'Создавайте описание товаров за несколько секунд'}
            />
        </div>
    );
}

export default SignIn;