import { configureStore, combineReducers } from '@reduxjs/toolkit';
import reducer from './reducer';

const combinedReducer = combineReducers(reducer);

const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        state.descriptions = undefined;
    }
    return combinedReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
