import axiosInstance from "./api";
import TokenService from "./tokenService";
import { refreshToken } from './../store/auth/AuthThunks'

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if ((originalConfig.url !== "/auth/sign_in" && originalConfig.url !== "/auth/refresh") && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          await dispatch(refreshToken())
          originalConfig.headers.Authorization = `Bearer ${TokenService.getLocalAccessToken()}`;
          const resp = await axiosInstance(originalConfig);

          return Promise.resolve(resp);
        }
        return Promise.reject(err);
      }
    }
  );
};

export default setup;