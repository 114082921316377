import React from 'react';
import Tooltip from '../../Tooltip/Tooltip';
import { ReactComponent as LoaderIcon } from './../../../assets/icons/loader.svg'

import './Button.scss';

const Button = ({ className, onClick, title, type='button', disabled = false, tooltip, step, tooltipStep, changeTooltipStep, isLoading = false}) => {
    return (
        <div className='btn_wrap'>
            {
                !isLoading
                ?
                <button className={"btn " + className} onClick={onClick} disabled={disabled} type={type}>{title}</button>
                :
                <LoaderIcon className='loader-icon'/>
            }
            { tooltip ? // add isOpenTooltip
                <Tooltip className={tooltip === 'top' ? 'tooltip--top': 'tooltip--bottom'} step={step} tooltipStep={tooltipStep} changeTooltipStep={changeTooltipStep} />
            :
                null
            }
        </div>
    );
}

export default Button;
