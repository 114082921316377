import React, { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import CreateDescription from '../../components/CreateDescription/CreateDescription';
import RequestHistory from '../../components/RequestHistory/RequestHistory';
import TopButton from '../../components/TopButton/TopButton';

import './Home.scss';
import { useActions } from '../../hooks/useActions';
import { useSelector } from '../../hooks/useSelector';

const Home = () => {
    const { getRequestsHistory } = useActions()
    const { data } = useSelector(state => state.descriptions)
    const [showButton, setShowButton] = useState(false)


    useEffect(() => {
        getRequestsHistory()

        const handleScrollButtonVisibility = () => {
            window.pageYOffset > 600 ? setShowButton(true) : setShowButton(false)
        }

        window.addEventListener('scroll', handleScrollButtonVisibility)

        return () => {
            window.removeEventListener('scroll', handleScrollButtonVisibility)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div className="home">
            <Header />
            <div className="home__container">
                <CreateDescription />
                <RequestHistory data={data} />
                <TopButton showButton={showButton} />
            </div>
        </div>
    );
}

export default Home;
