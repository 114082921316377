import React, { useEffect } from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import GenerationDescriptions from './pages/GenerationDescriptions/GenerationDescriptions';
import SignUp from './pages/SignUp/SignUp';
import SignUpCode from './pages/SignUp/SignUpCode';
import SignIn from './pages/SignIn/SignIn';
import RestorePassword from './pages/RestorePassword/RestorePassword';
import RestorePasswordSend from './pages/RestorePassword/RestorePasswordSend';
import RestorePasswordNew from './pages/RestorePassword/RestorePasswordNew';
import { useSelector } from './hooks/useSelector';
import { useActions } from './hooks/useActions';
import AppLoadingScreen from './components/AppLoadingScreen/AppLoadingScreen';
import TokenServiceInstance from './services/tokenService';

const App = () => {
    const navigate = useNavigate()
    const { isAuth, appLoading } = useSelector(state => state.auth)
    const { getAuth, confirmEmail, removeAppLoading } = useActions()

    useEffect(() => {
        if (TokenServiceInstance.getLocalAccessToken()) {
            getAuth()
        } else if (window.location.pathname === '/sign-in') {
            const confirmationToken = new URLSearchParams(window.location.search).get('confirmation-token')
            if (confirmationToken) {
                confirmEmail({ confirmationToken, navigate })
            } else removeAppLoading()
        } else removeAppLoading()
        // eslint-disable-next-line
    }, [])

    return (
            appLoading
            ?   <AppLoadingScreen />
            :   <div className="App">
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route index element={<Navigate to={isAuth ? 'home' : 'sign-in'} replace />}></Route>
                        {
                            isAuth
                        ?
                            <>
                                <Route path='home'>
                                    <Route index element={<Home />}></Route>
                                </Route>
                                <Route path='generation'>
                                    <Route index element={<GenerationDescriptions />}></Route>
                                </Route>
                            </>
                        :
                            <>
                                <Route path='sign-up'>
                                    <Route index element={<SignUp />}></Route>
                                    <Route path='code' element={<SignUpCode />}></Route>
                                </Route>
                                <Route path='sign-in' element={<SignIn />}></Route>
                                <Route path='restore-password'>
                                    <Route index element={<RestorePassword />}></Route>
                                    <Route path='send' element={<RestorePasswordSend />}></Route>
                                    <Route path='new' element={<RestorePasswordNew />}></Route>
                                </Route>
                            </>
                        }
                        <Route path='*' element={<Navigate to={isAuth ? 'home' : 'sign-in'} />} />
                    </Route>
                </Routes>
            </div>
    )
}

export default App;