import React from 'react';
import { Outlet } from 'react-router-dom';

import './Layout.scss';

const Layout = () => {
    return (
        <div className='wrapper'>
            <Outlet />
        </div>
    );
}

export default Layout;