import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useActions } from '../../../hooks/useActions';


const initialState = {
  email: '',
  password: '',
  password_confirmation: '',
};

const validation = {
  email: Yup.string().email('Некорректный email').required('Обязательное поле'),
  password: Yup.string()
      .oneOf([Yup.ref('password_confirmation'), null], 'Пароли не совпадают').required('Обязательное поле'),
  password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли не совпадают').required('Обязательное поле')
};

const yupValidationScheme = Yup.object({ ...validation });


const SignUpForm = ({ isSendEmail, setEmail }) => {
  const { signUp } = useActions()

  const [incorrectData, setIncorrectData] = useState()

  const handleSubmit = async (values, { setSubmitting, errors, setStatus, resetForm }) => {
    signUp({ values, setEmail, isSendEmail, setIncorrectData })
    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialState}
      validationSchema={yupValidationScheme}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, isSubmitting, status, errors, touched, handleChange }) => {
        return (
          <Form>
            <Input
              name={'email'}
              className={`auth__input`}
              id={'signup-email'}
              label={'Email'}
              placeholder={'Введите почту'}
              error={'Неверная почта!'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              incorrectData={incorrectData}
              setIncorrectData={setIncorrectData}
            />
            <Input
              name={'password'}
              className={'auth__input'}
              id={'signup-password'}
              label={'Пароль'}
              placeholder={'Введите пароль'}
              type={'password'}
              error={'Неверный пароль!'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              incorrectData={incorrectData}
              setIncorrectData={setIncorrectData}
            />
            <Input
              name={'password_confirmation'}
              className={'auth__input'}
              id={'signup-confirm-password'}
              label={'Подтвердите пароль'}
              placeholder={'Введите пароль'}
              type={'password'}
              error={'Неверный пароль!'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              incorrectData={incorrectData}
              setIncorrectData={setIncorrectData}
            />
            {
              incorrectData &&
              <p className='error request-error'>Пользователь с данным почтовым адресом уже существует!</p>
            }
            {
              errors && touched.email && touched.password && touched.password_confirmation &&
              <p className='error request-error'>
                {
                  errors.email ||
                  (errors.password === 'Обязательное поле' && errors.password) ||
                  (errors.password_confirmation === 'Обязательное поле' && errors.password_confirmation) ||
                  (errors.password === 'Пароли не совпадают' && errors.password)
                }
              </p>
            }
            <Button className={'auth__btn'} title={'Создать аккаунт'} type='submit'/>
          </Form>
        );
      }}
    </Formik>
  )
}

export default SignUpForm