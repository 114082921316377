import React, { useEffect } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';


const initialState = {
  email: '',
  password: '',
  password_confirmation: '',
  reset_password_token: '',
};

const validation = {
  password: Yup.string().oneOf([Yup.ref('password_confirmation'), null], 'Пароли не совпадают').required('Обязательное поле'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли не совпадают').required('Обязательное поле')
};

const yupValidationScheme = Yup.object({ ...validation });

const isExistSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search)

  return ({
    reset_password_token: searchParams.get('reset_password_token'),
    email: searchParams.get('email'),
  })
}

const UpdatePassForm = () => {
  const navigate = useNavigate()
  const { setIsOpenModalAfterResPass, updatePass } = useActions()

  useEffect(() => {
    const { reset_password_token, email } = isExistSearchParams()

    if (!(reset_password_token && email)) {
      navigate('/sign-in')
    }
  }, [navigate])

  const handleSubmit = async (values, { setSubmitting, errors, setStatus, resetForm }) => {
    const { reset_password_token, email } = isExistSearchParams()

    if (reset_password_token && email) {
      values = {
        ...values,
        reset_password_token,
        email
      }

      updatePass({ values, setIsOpenModalAfterResPass, navigate })
    }

    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialState}
      validationSchema={yupValidationScheme}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, isSubmitting, status, errors, touched, handleChange }) => {
        return (
          <Form>
            <Input
              name={'password'}
              className={'auth__input'}
              id={'new-password'}
              label={'Введите новый пароль'}
              placeholder={'Ввести пароль'}
              type={'password'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
            />
            <Input
              name={'password_confirmation'}
              className={'auth__input'}
              id={'new-password-confirm'}
              label={'Повторите пароль'}
              placeholder={'Ввести пароль'}
              type={'password'}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
            />
            {
              errors && touched.password && touched.password_confirmation &&
              <p className='error request-error'>
                {
                  (errors.password === 'Обязательное поле' && errors.password) ||
                  (errors.password_confirmation)
                }
              </p>
            }
            <Button className={'auth__btn'} title={'Восстановить'} type='submit'/>
          </Form>
        );
      }}
    </Formik>
  )
}

export default UpdatePassForm