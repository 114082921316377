import React, { useMemo } from 'react';
import { getColor } from '../../helpers/getColor';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useSelector } from '../../hooks/useSelector';

import './Keywords.scss';



const ClueKeywords = (props) => {
    const { keywords } = useSelector(state => state.descriptions)
    const ref = useClickOutside(() => props.setFocusKeywords(false))

    const handleAddKeyWord = item => {
        props.setValues(prevState => {
            if (!props.values.keywords.filter(keyword => keyword.text === item.text).length) {
                prevState.keywords.push({
                    text: item.text,
                    id: item.id,
                    frequency: item.frequency
                })
            }
            return ({
                ...prevState,
                rawKeywords: ''
            })
        })
    }

    const filteredRequestedKeywords = useMemo(
        () => keywords.filter(item => item.text.includes(props.values.rawKeywords) && !props.values.keywords.filter(keyword => keyword.text === item.text).length),
        //eslint-disable-next-line
        [props.values.rawKeywords, props.values.keywords.length, keywords]
    )

    return (
        <>
            {
                (
                    !(!!props.values.rawKeywords &&
                    !filteredRequestedKeywords.length &&
                    !!props.values.keywords.filter(item => item.text === props.values.rawKeywords).length) ||
                    (!props.values.rawKeywords && !!keywords.length)
                ) &&
            <div className={"keywords " + props.className}>
            <ul className="keywords__list" ref={ref}>
                {
                    !!filteredRequestedKeywords.length &&
                    filteredRequestedKeywords.map(item => {
                        const { bg, border } = getColor(item.frequency)
                        return <li className="keywords__item" onClick={() => handleAddKeyWord(item)} key={item.id}>
                            <button className="keywords__elem" style={{ '--bg': bg, '--border': border }} type='button'>
                                <span className="keywords__text">
                                    {item.text}
                                </span>
                                <span className="keywords__text">
                                    {item.frequency}
                                </span>
                            </button>
                        </li>
                    })
                }
                {
                    (
                        !!props.values.rawKeywords &&
                        !filteredRequestedKeywords.length &&
                        !props.values.keywords.filter(item => item.text === props.values.rawKeywords).length
                    ) &&
                    <li className="keywords__item" onClick={() => handleAddKeyWord({text: props.values.rawKeywords, frequency: 'N/A'})}>
                        <button className="keywords__elem" style={{ '--bg': '#E0E0E0', '--border': '#C6C6C6' }} type='button'>
                            <span className="keywords__text">
                                {props.values.rawKeywords}
                            </span>
                            <span className="keywords__text">
                                N/A
                            </span>
                        </button>
                    </li>
                }
            </ul>
            </div>
            }
        </>
        // }
        // </>
    );
}

export default ClueKeywords;