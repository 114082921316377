class TokenService {

  getLocalAccessToken() {
    return localStorage.getItem("access_token");
  }

  getLocalRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  updateLocalAccessToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }

  getUser() {
    return localStorage.getItem("access_token");
  }

  setUser({ refresh_token, access_token }) {
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("access_token", access_token);
  }

  removeUser() {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
  }
}

const TokenServiceInstance = new TokenService()

export default TokenServiceInstance;
// export default new TokenService();