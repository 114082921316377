import format from 'date-fns/format';
import React, { useMemo, useState } from 'react';

import './RequestHistory.scss';

const RequestHistory = ({ data }) => {

    const [maxRequests, setMaxRequests] = useState(6);

    const displayRequests = useMemo(() => data.slice(0, maxRequests), [data, maxRequests])

    const handleIncrement = () => {
        setMaxRequests(prev => prev + 6)
        var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({ id: '3317476', type: 'reachGoal', goal: 'load_more_history_requests' });
    }

    return (
        <div className='request-history'>
            <h2 className="request-history__heading">История запросов</h2>
            <div className="request-history__wrapper">
                {
                    displayRequests.map(item => (
                        <div className="request-history__item" key={item.id}>
                            <div className="request-history__block">
                                <div className="request-history__inner">
                                    <h3 className="request-history__title">
                                        {item.name}
                                    </h3>
                                    <span className="request-history__data">
                                        {format(new Date(`${item.generated_at}Z`), 'dd.MM.yyyy в HH:mm')}
                                    </span>
                                </div>
                                <p className="request-history__description">
                                    {item.description}
                                </p>
                            </div>
                            <div className="request-history__block">
                                <span className="request-history__value">Слов <b>
                                    {item.description.match(/\p{L}+/gu).length}
                                </b></span>
                                <span className="request-history__value">Символов <b>
                                    {item.description.length}
                                </b></span>
                            </div>
                        </div>
                    ))
                    // :
                    // <div className="request-history__item">
                    //     <div className="request-history__block">
                    //         <div className="request-history__inner">
                    //             <h3 className="request-history__title">Шорты спортивные</h3>
                    //             <span className="request-history__data">06.02.23 в 22:31</span>
                    //         </div>
                    //         <p className="request-history__description">Шорты сшиты из хлопка премиум качества, плотные, удлиненные со шнурком и утягивающей резинкой в премиум качества ...</p>
                    //     </div>
                    //     <div className="request-history__block">
                    //         <span className="request-history__value">Слов <b>259</b></span>
                    //         <span className="request-history__value">Символов <b>1200</b></span>
                    //     </div>
                    // </div>
                }
                {/* <div className="request-history__item">
                    <div className="request-history__block">
                        <div className="request-history__inner">
                            <h3 className="request-history__title">Шорты спортивные</h3>
                            <span className="request-history__data">06.02.23 в 22:31</span>
                        </div>
                        <p className="request-history__description">Шорты сшиты из хлопка премиум качества, плотные, удлиненные со шнурком и утягивающей резинкой в премиум качества ...</p>
                    </div>
                    <div className="request-history__block">
                        <span className="request-history__value">Слов <b>259</b></span>
                        <span className="request-history__value">Символов <b>1200</b></span>
                    </div>
                </div>
                <div className="request-history__item">
                    <div className="request-history__block">
                        <div className="request-history__inner">
                            <h3 className="request-history__title">Шорты спортивные</h3>
                            <span className="request-history__data">06.02.23 в 22:31</span>
                        </div>
                        <p className="request-history__description">Шорты сшиты из хлопка премиум качества, плотные, удлиненные со шнурком и утягивающей резинкой в премиум качества ...</p>
                    </div>
                    <div className="request-history__block">
                        <span className="request-history__value">Слов <b>259</b></span>
                        <span className="request-history__value">Символов <b>1200</b></span>
                    </div>
                </div>
                <div className="request-history__item">
                    <div className="request-history__block">
                        <div className="request-history__inner">
                            <h3 className="request-history__title">Шорты спортивные</h3>
                            <span className="request-history__data">06.02.23 в 22:31</span>
                        </div>
                        <p className="request-history__description">Шорты сшиты из хлопка премиум качества, плотные, удлиненные со шнурком и утягивающей резинкой в премиум качества ...</p>
                    </div>
                    <div className="request-history__block">
                        <span className="request-history__value">Слов <b>259</b></span>
                        <span className="request-history__value">Символов <b>1200</b></span>
                    </div>
                </div>
                <div className="request-history__item">
                    <div className="request-history__block">
                        <div className="request-history__inner">
                            <h3 className="request-history__title">Шорты спортивные</h3>
                            <span className="request-history__data">06.02.23 в 22:31</span>
                        </div>
                        <p className="request-history__description">Шорты сшиты из хлопка премиум качества, плотные, удлиненные со шнурком и утягивающей резинкой в премиум качества ...</p>
                    </div>
                    <div className="request-history__block">
                        <span className="request-history__value">Слов <b>259</b></span>
                        <span className="request-history__value">Символов <b>1200</b></span>
                    </div>
                </div> */}
            </div>
            {
                data.length > maxRequests
                ?
                <button className="request-history__more" onClick={handleIncrement}>Загрузить ещё</button>
                :
                null
            }
        </div>
    );
}

export default RequestHistory;