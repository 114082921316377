import React from 'react';

import Button from '../UI/Button/Button';

import './Tooltip.scss';

import sprite from '../../assets/images/sprite.svg';

const tooltipsContent = [
    {
        step: 1,
        descr: 'Введите сюда технические характеристики товара, такие как состав, емкость аккумулятора, вес, размер, плотность и др.',
        example: 'Например: роторный двигатель до 6500 об/мин, корпус из алюминиевого сплава, время работы 5 часов, рычаг для изменения длины стрижки от 1 от 3 мм, лезвие из нержавеющей стали, ширина лезвия 45 мм, аккумулятор 2500 mA/h.',
    },
    {
        step: 2,
        descr: 'Введите сюда преимущества и отличительные особенности товара.',
        example: 'Например: удобно лежит в руке, подходит для стрижки детей и взрослых, цветной дисплей с информацией, винтажный стиль, цельный корпус, премиальное качество.',
    },
    {
        step: 3,
        descr: 'Выберите ключевые слова из предложенного списка или напишите свои. Справа показана частотность запроса, т.е. как часто люди ищут товар именно по этому ключевому слову.',
        example: 'Ключевые слова собраны на основе недавних поисковых запросов на Wildberries.',
    },
    {
        step: 4,
        descr: 'На каждый набор вводных данных вы можете сделать по 3 генерации.',
        example: 'При изменении характеристик, дополнительной информации и ключевых слов, генерация будет считаться как новая и вычитаться из тарифа.',
    },
]


const Tooltip = (props) => {

    const handleBackStep = () => {
        props.changeTooltipStep(prev => prev - 1)
    }

    const handleForwardStep = () => {
        props.changeTooltipStep(prev => {
            return props.step !== 4 ? prev + 1 : 0;
        })
    }

    return (
        <div className={`tooltip ${props.className} ${props.step === props.tooltipStep ? 'display-tooltip' : ''}`}>
            <div className="tooltip__wrapper">
                <div className="tooltip__top">
                    <span className="tooltip__title">Шаг {tooltipsContent[props.step - 1].step}/4</span>
                    <button className="tooltip__close" onClick={() => props.changeTooltipStep(0)}>
                        <svg className="tooltip__close-icon">
                            <use href={sprite + '#icon-close'}></use>
                        </svg>
                    </button>
                </div>
                <div className="tooltip__body">
                    <p className="tooltip__description">
                        {tooltipsContent[props.step - 1].descr}
                    </p>
                    <p className="tooltip__example">
                        {tooltipsContent[props.step - 1].example}
                    </p>
                </div>
                <div className="tooltip__bottom">
                    <Button title="Назад" className="btn--grey tooltip__btn" disabled={props.step === 1} onClick={handleBackStep}/>
                    <Button title={props.step === 4 ? 'Начать' : 'Далее'} className="tooltip__btn" onClick={handleForwardStep} />
                </div>
            </div>
        </div>
    );
}

export default Tooltip;