import React from 'react';

import './Keywords.scss';

import sprite from '../../assets/images/sprite.svg';
import { getColor } from '../../helpers/getColor';

const ClueKeywords = (props) => {

    const handleRemoveKeyword = item => {
        props.setValues(prev => ({
            ...prev,
            keywords: prev.keywords.filter(keyword => keyword.id !== item.id)
        }))
    }

    return (
        <div className={"keywords " + props.className}>
            <ul className="keywords__list">
                {
                    !!props.values.keywords.length &&
                    props.values.keywords.map(item => {
                        const { bg, border } = getColor(item.frequency)
                        return (
                            <li className="keywords__item" key={item.id} onClick={() => handleRemoveKeyword(item)}>
                                <div className="keywords__elem" style={{ '--bg': bg, '--border': border }}>
                                    <span className="keywords__text">
                                        {item.text}
                                    </span>
                                    <span className="keywords__text">
                                        {item.frequency}
                                    </span>
                                    <button className="keywords__delete" type='button'>
                                        <svg className="keywords__delete-icon">
                                            <use href={sprite + '#icon-close'}></use>
                                        </svg>
                                    </button>
                                </div>
                            </li>
                        )
                    })
                }
                {/* <li className="keywords__item">
                    <div className="keywords__elem" style={{ '--bg': '#F2FAF5', '--border': '#DFEDE3' }}>
                        <span className="keywords__text">шорты для спорта</span>
                        <span className="keywords__text">102021</span>
                        <button className="keywords__delete">
                            <svg className="keywords__delete-icon">
                                <use href={sprite + '#icon-close'}></use>
                            </svg>
                        </button>
                    </div>
                </li>
                <li className="keywords__item">
                    <div className="keywords__elem" style={{ '--bg': '#F2FAF5', '--border': '#DFEDE3' }}>
                        <span className="keywords__text">шорты для спорта</span>
                        <span className="keywords__text">102021</span>
                        <button className="keywords__delete">
                            <svg className="keywords__delete-icon">
                                <use href={sprite + '#icon-close'}></use>
                            </svg>
                        </button>
                    </div>
                </li>
                <li className="keywords__item">
                    <div className="keywords__elem" style={{ '--bg': '#FFECC9', '--border': '#FFCF72' }}>
                        <span className="keywords__text">шорты для плаванья</span>
                        <span className="keywords__text">102021</span>
                        <button className="keywords__delete">
                            <svg className="keywords__delete-icon">
                                <use href={sprite + '#icon-close'}></use>
                            </svg>
                        </button>
                    </div>
                </li>
                <li className="keywords__item">
                    <div className="keywords__elem" style={{ '--bg': '#FFCFC9', '--border': '#D0867C' }}>
                        <span className="keywords__text">шорты джинсовые</span>
                        <span className="keywords__text">21</span>
                        <button className="keywords__delete">
                            <svg className="keywords__delete-icon">
                                <use href={sprite + '#icon-close'}></use>
                            </svg>
                        </button>
                    </div>
                </li> */}
            </ul>
        </div>
    );
}

export default ClueKeywords;