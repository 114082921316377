import React, { useState } from 'react';

import CustomLink from '../../components/CustomLink/CustomLink';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';

import './Auth.scss';

import Logotype from "../../assets/images/logotype.svg";
import Image from "../../assets/images/murr-door.svg";
import Done from "../../assets/images/done.svg";
import SignUpForm from '../Forms/SignUp/SignUpForm';
import SignInForm from '../Forms/SignIn/SignInForm';
import RestorePassForm from '../Forms/RestorePass/RestorePassForm';
import UpdatePassForm from '../Forms/UpdatePass/UpdatePassForm';
import ModalPassword from '../../components/Modal/ModalPassword';
import { useSelector } from '../../hooks/useSelector';
import ConfirmEmailModal from '../Modal/ConfirmEmailModal';

const Auth = (props) => {

    const { isOpenModalAfterResPass, isOpenModalAfterConfirmEmail } = useSelector(state => state.auth)
    const [signUpSend, setSignUpSend] = useState(false)
    const [restoreSend, setRestoreSend] = useState(false)
    const [emailRecipient, setEmailRecipient] = useState('')

    return (
        <div className="auth">
            { (props.signup && !signUpSend) ?
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <SignUpForm isSendEmail={setSignUpSend} setEmail={setEmailRecipient}/>
                                <p className="auth__help">Уже есть аккаунт? <CustomLink to='/sign-in'>Войти</CustomLink></p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                : (props.signup && signUpSend) ?
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <div className="auth__message">
                                    <picture className="auth__message-icon">
                                        <img src={Done} alt="Success" />
                                    </picture>
                                    <span className="auth__message-title">Письмо отправлено</span>
                                </div>
                                <p className="auth__description">Перейдите по ссылке из письма, которое отправлено на почту <b>{emailRecipient}</b>
                                <br /><br />
                                На всякий случай, проверьте папку спам
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                : props.signupcode ?
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <p className="auth__description">Код подтверждения отправлен на почту <b>le@tr.ru</b>. Вставьте его в поле ниже. </p>
                                <form action="#">
                                    <Input 
                                        className={'auth__input'}
                                        id={'signup-code'}
                                        label={'Введите код из письма'}
                                        placeholder={'Ввести код'}
                                        type={'text'}
                                        error={'Неверный код!'}
                                    />
                                    <Button className={'auth__btn'} title={'Создать аккаунт'}/>
                                </form>
                                <p className="auth__help">Уже есть аккаунт? <CustomLink to='/sign-in'>Войти</CustomLink></p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                : props.signin ?
                <div className="auth__wrapper">
                    {
                        isOpenModalAfterResPass &&
                        <ModalPassword />
                    }
                    {
                        isOpenModalAfterConfirmEmail &&
                        <ConfirmEmailModal />
                    }
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <SignInForm />
                                <p className="auth__help">Ещё нет аккаунта? <CustomLink to='/sign-up'>Зарегистрироваться</CustomLink></p>
                                <p className="auth__help">Забыли пароль? <CustomLink to='/restore-password'>Восстановить</CustomLink></p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                : (props.restorepassword && !restoreSend) ?
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <RestorePassForm setEmail={setEmailRecipient} isRestoreSend={setRestoreSend}/>
                                <p className="auth__help">Ещё нет аккаунта? <CustomLink to='/sign-up'>Зарегистрироваться</CustomLink></p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                : (props.restorepassword && restoreSend) ?
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <div className="auth__message">
                                    <picture className="auth__message-icon">
                                        <img src={Done} alt="Success" />
                                    </picture>
                                    <span className="auth__message-title">Письмо отправлено</span>
                                </div>
                                <p className="auth__description">Перейдите по ссылке из письма, которое отправлено на почту <b>{emailRecipient}</b>
                                <br /><br />
                                На всякий случай, проверьте папку спам</p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                : props.restorepasswordnew ?
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <UpdatePassForm />
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
                :
                <div className="auth__wrapper">
                    <div className="auth__left">
                        <div className="auth__container">
                            <div className="auth__top">
                                <picture className="auth__logotype">
                                    <img src={Logotype} alt="SOLLA" />
                                </picture>
                                { props.description ?
                                    <p className="auth__promo auth__promo--mobile">{props.description}</p>
                                :
                                    null
                                }
                            </div>
                            <div className="auth__body">
                                <h1 className="auth__heading">{props.heading}</h1>
                                <form action="#">
                                    <Input 
                                        className={'auth__input'}
                                        id={'signin-email'}
                                        label={'Email'}
                                        placeholder={'Введите почту'}
                                        type={'email'}
                                        error={'Неверная почта!'}
                                    />
                                    <Input 
                                        className={'auth__input'}
                                        id={'signin-password'}
                                        label={'Пароль'}
                                        placeholder={'Введите пароль'}
                                        type={'password'}
                                        error={'Неверный пароль!'}
                                    />
                                    <Button className={'auth__btn'} title={'Войти'}/>
                                </form>
                                <p className="auth__help">Ещё нет аккаунта? <CustomLink to='/sign-up'>Зарегистрироваться</CustomLink></p>
                                <p className="auth__help">Забыли пароль? <CustomLink to='/restore-password'>Восстановить</CustomLink></p>
                            </div>
                        </div>
                    </div>
                    <div className="auth__right">
                        { props.description ?
                            <p className="auth__promo">{props.description}</p>
                            :
                            null
                        }
                        <picture className="auth__img">
                            <img src={Image} alt="" />
                        </picture>
                        <div className="auth__decor auth__decor--first"></div>
                        <div className="auth__decor auth__decor--second"></div>
                        <div className="auth__decor auth__decor--third"></div>
                        <div className="auth__decor auth__decor--fourth"></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Auth;
