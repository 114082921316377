import React from 'react';

import Button from '../../components/UI/Button/Button';

import './Modal.scss';

import sprite from '../../assets/images/sprite.svg';
import { useActions } from '../../hooks/useActions';
import { useNavigate } from 'react-router';

const ConfirmEmailModal = () => {
    const navigate = useNavigate()

    const { setIsOpenModalAfterConfirmEmail } = useActions()

    const handleCloseModal = () => {
        setIsOpenModalAfterConfirmEmail()
        navigate('/sign-in')
    }

    return (
        <div className='modal modal--active'>
            <div className="modal__scroll">
                <div className="modal__wrap modal__wrap--sm">
                    <div className="modal__main">
                        <button className="modal__close" onClick={handleCloseModal}>
                            <svg className="modal__close-icon">
                                <use href={sprite + '#icon-close'}></use>
                            </svg>
                        </button>
                        <div className="modal__body">
                            <div className="modal__block">
                                <h3 className="modal__title">Email подтвержден</h3>
                            </div>
                            <Button className={'modal__btn'} title={'Войти в аккаунт'} onClick={handleCloseModal}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__overlay" onClick={handleCloseModal}></div>
        </div>
    );
}

export default ConfirmEmailModal;