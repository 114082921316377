import React from 'react';
import { Formik, Form } from 'formik'

import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';

import './CreateDescription.scss';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../hooks/useActions';
import { useSelector } from '../../hooks/useSelector';

const initialState = {
    rawName: ''
}

const CreateDescription = () => {
    const navigate = useNavigate()
    const { loading, numberOfGenerations } = useSelector(state => state.descriptions)
    const { setName, getKeywords, getNumberOfTry } = useActions()

    const handleSubmit = async (values, { setSubmitting, errors, setStatus, resetForm }) => {
        setName(values.rawName)
        navigate('/generation')
        getKeywords({name: values.rawName})
        getNumberOfTry({name: values.rawName.trim().toLowerCase()})
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={initialState}
            onSubmit={handleSubmit}
            >
                {({ values, setValues, isSubmitting, status, errors, touched, handleChange }) => {
                    return (
                    <Form className='create-description'>
                        <Input
                        name={'rawName'}
                        className={'create-description__input'}
                        id={'goods-name'}
                        placeholder={'Введите название товара'}
                        touched={touched}
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        />
                        <Button className={'create-description__btn'} title={'Создать описание'} type='submit' disabled={!values.rawName}/>
                        {
                            !loading && numberOfGenerations !== null &&
                            <p className='generations_quantity'>
                                {`Остаток описаний товаров по тарифу: ${numberOfGenerations}`}
                            </p>
                        }
                    </Form>
                    );
                }}
        </Formik>
    );
}

export default CreateDescription;