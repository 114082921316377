import React, { useMemo } from 'react';

import Tooltip from '../../Tooltip/Tooltip';

import './Input.scss';

import sprite from '../../../assets/images/sprite.svg';
import debounce from 'lodash.debounce';
import { useActions } from '../../../hooks/useActions';

const Input = (props) => {
    const { getKeywords, getNumberOfTry } = useActions()

    const changeHandler = async value => {
        getKeywords({name: value})
        getNumberOfTry({name: value.trim().toLowerCase()})
    }

    const debounced = useMemo(
        () => debounce(
            (e) => changeHandler(e.target.defaultValue),
            2000
        ),
        //eslint-disable-next-line
        []
    )

    const handleChangeValue = e => {
        if (props.incorrectData === true) {
            props.setIncorrectData(false)
        }
        if (props.name === 'rawCharacteristics') {
            props.touched.characteristics = false
        } else if (props.name === 'rawInfo') {
            props.touched.info = false
        } else props.touched[props.name] = false
        props.handleChange(e)
        if (props.name === 'name') {
            debounced(e)
        }
    }

    const handleOpenTooltips = () => {
        if (props.tooltipStep !== 0) {
            props.changeTooltipStep(0)
        } else props.changeTooltipStep(props.step)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()

            if ((props.name === 'rawCharacteristics' || props.name === 'rawInfo') && !!e.target.value) {
                props.setValues(prevState => {
                    const fieldName = props.name === 'rawCharacteristics' ? 'characteristics' : 'info'
                    if (!props.values[fieldName].filter(item => item.value === e.target.value).length) {
                        prevState[fieldName].push({value: e.target.value, id: e.target.value})
                        prevState[props.name] = ''
                    }
                    return ({
                        ...prevState,
                    })
                })
            }
        }
    }

    return (
        <div className={`input-field ${props.className} ${(
            (props.errors?.[props?.name] && props.touched?.[props?.name]) ||
            (props?.name === 'rawCharacteristics' && props.errors?.['characteristics'] && props.touched?.['characteristics']) ||
            (props?.name === 'rawInfo' && props.errors?.['info'] && props.touched?.['info'])
            ) ? 'is-error' : ''}`}>
            { props.label ?
                <div className="input-field__inner">
                    <label htmlFor={props.id} className="input-field__label">
                        <span>{props.label}</span>
                        { props.tooltip ?
                            <button type='button' className="input-field__tooltip" onClick={handleOpenTooltips}>
                                <svg className="input-field__tooltip-icon">
                                    <use href={sprite + '#icon-info'}></use>
                                </svg>
                            </button>
                        :
                            null
                        }
                    </label>
                </div>
              :
                null
            }
            {
                props.note &&
                <p className='input-field__note'>
                    {props.note}
                </p>
            }
            <input
                name={props.name}
                onChange={handleChangeValue}
                value={props.values[props.name]}
                type={props.type}
                id={props.id}
                className="input-field__input"
                placeholder={props.placeholder}
                onKeyDown={handleKeyDown}
            />
            { props.tooltip ? // add isOpenTooltip
                <Tooltip className={props.tooltip === 'top' ? 'tooltip--top': 'tooltip--bottom'} step={props.step} tooltipStep={props.tooltipStep} changeTooltipStep={props.changeTooltipStep} />
            :
                null
            }
            {
                (   props.isDisplayError &&
                    ((props.errors?.[props?.name] && props.touched?.[props?.name]) ||
                    (props?.name === 'rawCharacteristics' && props.errors?.['characteristics'] && props.touched?.['characteristics']) ||
                    (props?.name === 'rawInfo' && props.errors?.['info'] && props.touched?.['info']))
                ) ?
                <p className='error request-error'>
                    {
                        (props?.name === 'rawCharacteristics' &&
                        props.errors?.characteristics) ||
                        (props?.name === 'rawInfo' &&
                        props.errors?.info) ||
                        props.errors?.[props.name]
                    }
                </p> :
                null
            }
        </div>
    );
}

export default Input;
