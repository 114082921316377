import React from 'react';

import Auth from '../../components/Auth/Auth';

const RestorePasswordNew = () => {
    return (
        <div className="restorepassword">
            <Auth 
                restorepasswordnew
                heading={'Восстановление пароля'}
                description={'Создавайте описание товаров за несколько секунд'}
            />
        </div>
    );
}

export default RestorePasswordNew;