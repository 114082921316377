import React from 'react';

import Auth from '../../components/Auth/Auth';

const RestorePasswordSend = () => {
    return (
        <div className="restorepassword">
            <Auth 
                restorepasswordsend
                heading={'Восстановление пароля'}
                description={'Создавайте описание товаров за несколько секунд'}
            />
        </div>
    );
}

export default RestorePasswordSend;