import React from 'react';

import './TopButton.scss';

import sprite from '../../assets/images/sprite.svg';

const TopButton = ({ showButton }) => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <button className={`top-button ${showButton ? 'show_button' : ''}`} onClick={handleScrollToTop}>
            <svg className="top-button__icon">
                <use href={sprite + '#icon-arrow-top'}></use>
            </svg>
        </button>
    );
}

export default TopButton;