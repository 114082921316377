import React from 'react';

import Auth from '../../components/Auth/Auth';

const SignUpCode = () => {
    return (
        <div className="signup">
            <Auth 
                signupcode
                heading={'Регистрация'}
                description={'Создавайте описание товаров за несколько секунд'}
            />
        </div>
    );
}

export default SignUpCode;